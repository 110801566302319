import React, { useEffect, useState } from "react";
import { Layout as AntdLayout } from "antd";
import { connect } from "react-redux";
import classNames from "classnames";
import { checkIsLogged } from "../_auth/methods";
import { useHtmlSize } from "../../utils/hooks/useHtmlSize";
import { usePrevious } from "../../utils/hooks/usePrevious";
import Sidebar from "./Sidebar";
import Header from "./Header";
import MainContainer from "./MainContainer";
import AppFooter from "./Footer";
import StatusBar from "./StatusBar";

const COLLAPSED_SIDEBAR_WIDTH = 1170;

/**
 * Layout
 * @function Layout
 * @description Layout component
 */
const Layout = ({ children, user }) => {
  const isLogged = checkIsLogged(user);
  const windowSize = useHtmlSize();
  const previousWindowSize = usePrevious(windowSize);
  const isFooterVisible = !!user?.company?.footer;
  const [countSystemStatuses, setCountSystemStatuses] = useState(0);

  const [collapsed, setCollapsed] = useState(window.innerWidth < COLLAPSED_SIDEBAR_WIDTH);
  const handleCollapse = (collapse) => {
    setCollapsed(collapse);
  };

  useEffect(() => {
    if (windowSize?.width <= COLLAPSED_SIDEBAR_WIDTH && !collapsed && windowSize?.width !== previousWindowSize?.width) {
      setCollapsed(true);
    }

    if (windowSize?.width > COLLAPSED_SIDEBAR_WIDTH && collapsed && windowSize?.width !== previousWindowSize?.width) {
      setCollapsed(false);
    }
  }, [windowSize, collapsed, previousWindowSize]);

  useEffect(() => {
    const appContainerElement = document.getElementsByClassName("app-global-container")[0];

    if (appContainerElement) {
      appContainerElement.classList[collapsed ? "add" : "remove"]("navigation-collapsed");
    }
  }, [collapsed]);

  if (!isLogged) {
    return children;
  }

  const marginTop = countSystemStatuses ? `${countSystemStatuses * 32}px` : undefined;

  return (
    <AntdLayout>
      <StatusBar setCountSystemStatuses={setCountSystemStatuses} />
      <Header handleCollapse={handleCollapse} collapsed={collapsed} marginStatusBar={marginTop} />
      <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} marginStatusBar={marginTop} />
      <AntdLayout
        className={classNames("site-layout", { "footer-visible": isFooterVisible })}
        style={{ marginTop: marginTop ?? "inherit" }}
      >
        <MainContainer>{children}</MainContainer>
        {isFooterVisible && <AppFooter company={user.company} />}
      </AntdLayout>
    </AntdLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(Layout);
