import React, { useContext, useState } from "react";
import { Dropdown } from "antd";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import { userUpdatedAction } from "../_auth/reducer";
import i18n from "../../i18n";
import { useLoggedUser } from "../../utils/hooks/useLoggedUser";
import moment from "../../moment";
import { SystemStatusContext } from "../../context/SystemStatusContext";
import DropdownLanguagesOverlay from "./DropdownLanguagesOverlay";
import { convertFromLongLanguageToCode, generateAvailableLanguage } from "./methods";

const DropdownLanguages = () => {
  const dispatch = useDispatch();
  const { fetchStatuses } = useContext(SystemStatusContext);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const loggedUser = useLoggedUser();
  const availableLanguages = generateAvailableLanguage(loggedUser.company);
  const [selectedLanguage, setSelectedLanguage] = useState(
    availableLanguages.filter((lang) => lang.code === loggedUser.language)[0] || availableLanguages[0]
  );
  const onSelectLanguage = (lang) => {
    setVisible(false);

    if (loading) {
      return;
    }

    axios.defaults.headers.locale = lang.code;
    setLoading(true);
    axios.put("users/language", { language: lang.code }).then(
      () => {
        setLoading(false);
        fetchStatuses();
      },
      () => {
        setLoading(false);
      }
    );

    dispatch(userUpdatedAction({ ...loggedUser, language: lang.code }));
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang.code);
    moment.locale(convertFromLongLanguageToCode(lang.code));
  };

  return (
    <Dropdown
      overlay={
        <DropdownLanguagesOverlay
          onSelectLanguage={onSelectLanguage}
          selectedLanguage={selectedLanguage}
          availableLanguages={availableLanguages}
        />
      }
      visible={visible}
      onVisibleChange={(vis) => setVisible(vis)}
      trigger={["click"]}
      overlayClassName="dropdown-languages-overlay simple-dropdown-overlay"
      getPopupContainer={(el) => el}
    >
      <div className="link languages-trigger mr-15">
        <img src={selectedLanguage.icon} alt="icon" />
      </div>
    </Dropdown>
  );
};

export default DropdownLanguages;
