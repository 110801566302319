import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import TableService from "../../../components/SmartTable/TableService";
import SmartTable from "../../../components/SmartTable";
import { useLoggedUser } from "../../../utils/hooks/useLoggedUser";
import PageMetaTagTitle from "../../../components/PageMetaTagTitle";
import { useTableFullHeight } from "../../../components/SmartTable/useTableFullHeight";
import { openModalAction } from "../../../components/Modal/reducer";
import ResourceSelectLazyLoading from "../../../components/ResourceSelectLazyLoading";
import { reloadTable } from "../../../components/SmartTable/reducer";
import DatePicker from "../../../components/DatePicker";
import moment from "../../../moment";
import config from "../../../config";
import Button from "../../../components/Button";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import LoadingModal, { MODAL_EXPORT_LOADING } from "../../../components/SmartTable/ExportDropdown/LoadingModal";
import { PAGE_TRG_MASS_ASSIGNMENT } from "../routes";
import { useAxiosRequest } from "../../../utils/hooks/useAxios";
import { getBranchesData } from "../../Organization/OrganizationChart/helpers";
import { generateColumns, prepareQualificationsData, TABLE_QUALIFICATIONS } from "./methods";
import { contractTypeOptions, qualificationStatusOptions } from "./config";

export const MODAL_QUALIFICATION = "MODAL_QUALIFICATION";

const Qualifications = () => {
  const location = useLocation();
  const locationStatus = location.state?.status;
  const history = useHistory();
  const { format } = useDateFormat();
  const dispatch = useDispatch();
  const tableHeight = useTableFullHeight();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const tableKeyName = `${TABLE_QUALIFICATIONS}_${loggedUser.id}`;
  const [totalItems, setTotalItems] = useState();
  const [shouldReset, setShouldReset] = useState();
  const [apiEmployeeStatusOptions, setApiEmployeeStatusOptions] = useState([]);
  const [apiJobTitleOptions, setApiJobTitleOptions] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("smartTableLastPage") ? parseInt(localStorage.getItem("smartTableLastPage"), 10) : 1
  );
  const [currentSearchValue, setCurrentSearchValue] = useState(
    localStorage.getItem("smartTableLastSearch") ? localStorage.getItem("smartTableLastSearch") : ""
  );

  const employeeStatusState = useAxiosRequest({
    route: "employee-status",
    params: {},
    onSuccess: (res) => {
      setApiEmployeeStatusOptions(res?.data?.data);
    }
  });

  const jobTitleState = useAxiosRequest({
    route: "job-titles?order_by_column%5B%5D=name&order_by_type%5B%5D=asc",
    params: {},
    onSuccess: (res) => {
      setApiJobTitleOptions(res?.data?.data);
    }
  });

  const branchesOptions = useMemo(() => {
    if (branchesData.length) {
      return branchesData.map((branch) => {
        return { value: branch.id, text: branch.name };
      });
    }
  }, [branchesData]);

  const localStorageQualificationsApply = useRef(
    JSON.parse(localStorage.getItem("qualificationsPageStateApply")) || false
  );

  const localStorageQualificationsTable = useRef(
    localStorageQualificationsApply.current ? JSON.parse(localStorage.getItem("qualificationsPageState")) || {} : {}
  );

  const defaultFilters = locationStatus
    ? {
        anotherFilters: [
          { nameKey: "qualificationStatus", value: locationStatus },
          { nameKey: "validContract", value: 1 }
        ]
      }
    : {
        ...JSON.parse(localStorage.getItem(tableKeyName) || "{}"),
        anotherFilters: [
          { nameKey: "validContract", value: 1 },
          { nameKey: "qualificationStatus", value: "all_without_previous_function" }
        ]
      };

  const localStorageTableFilters = localStorageQualificationsApply.current
    ? {
        ...localStorageQualificationsTable.current.filters,
        anotherFilters: localStorageQualificationsTable.current.filters ?? [
          { nameKey: "qualificationStatus", value: locationStatus },
          { nameKey: "validContract", value: 1 }
        ]
      }
    : defaultFilters;

  const contractFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "validContract"
  )[0];

  const statusFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "qualificationStatus"
  )[0];

  const employeeStatusFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "employeeStatusIds"
  )[0];

  const branchFilter = localStorageTableFilters?.anotherFilters?.filter((item) => item.nameKey === "branchId")[0];

  const departmentFilter = localStorageTableFilters?.anotherFilters?.filter(
    (item) => item.nameKey === "departmentId"
  )[0];

  const areaFilter = localStorageTableFilters?.anotherFilters?.filter((item) => item.nameKey === "areaId")[0];
  const jobTitleFilter = localStorageTableFilters?.anotherFilters?.filter((item) => item.nameKey === "jobTitleIds")[0];

  const dateFilter = localStorageTableFilters?.anotherFilters?.filter((item) => item.nameKey === "dateFilter")[0];

  const [tableService] = useState(
    new TableService({
      defaultWith: [
        "qualifications.training.validity",
        "qualifications.training.uncompletedCourses.participants",
        "userDetail",
        "activeStaffJobFunctions.staffFunctions.trainingPrograms.activeJobTitleTrainings.training.uncompletedCourses.participants"
      ],
      defaultOrderBy: [
        { column: "last_name", type: "asc" },
        { column: "first_name", type: "asc" }
      ],
      searchColumns: ["name"],
      ...localStorageTableFilters,
      searchValue: currentSearchValue !== "" && localStorageQualificationsApply?.current ? currentSearchValue : "",
      page: currentPage !== 1 && localStorageQualificationsApply?.current ? currentPage : 1
    })
  );

  useEffect(() => {
    // Check if the page was accessed from user page (Back button)
    const applyQualificationHistory = localStorage.getItem("qualificationsPageStateApply") || false;
    if (applyQualificationHistory === false) {
      localStorage.removeItem("qualificationsPageState");
      localStorage.removeItem("smartTableLastPage");
      localStorage.removeItem("smartTableLastSearch");
      setCurrentPage(1);
      setCurrentSearchValue("");
    }
    localStorage.removeItem("qualificationsPageStateApply");
  }, []);

  const customHandleTableChange = ({ pagination, filters, sorter, tableService, getTableData, columns }) => {
    tableService.setPage(pagination.current);
    tableService.removeAllOrderBy().manageOrderBy({ column: sorter.field, type: sorter.order });
    tableService.manageColumnFilters(filters, columns);

    saveFilterOnLocalStorage([...tableService.anotherFilters]);
    getTableData(tableService.getFilters());
    localStorage.setItem("smartTableLastPage", pagination.current);
  };

  useEffect(() => {
    if (locationStatus) {
      history.replace({ ...location, state: undefined });
    }
    getBranchesData(setBranchesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilters = () => {
    dispatch(reloadTable({ name: TABLE_QUALIFICATIONS, resetFilters: true }));
    setShouldReset(new Date().getTime());
    localStorage.removeItem("qualificationsPageStateApply");
    localStorage.removeItem("qualificationsPageState");
    localStorage.removeItem("smartTableLastPage");
    localStorage.removeItem("smartTableLastSearch");
  };

  const handleFilterChange = (filterName, value) => {
    tableService.removeAnotherFilter(filterName);
    if (value !== undefined) {
      tableService.addAnotherFilter({ nameKey: filterName, value });
    }
    saveFilterOnLocalStorage([...tableService.anotherFilters]);
    tableService.setPage(1);
    dispatch(reloadTable({ name: TABLE_QUALIFICATIONS }));
  };

  const saveFilterOnLocalStorage = (currentFilters) => {
    const newState = {
      filters: currentFilters
    };
    localStorage.setItem("qualificationsPageState", JSON.stringify(newState));
  };

  const prepopulateFilter = (value, staticOptions, apiOptions = []) => {
    if (!value) return undefined;

    const transformedApiOptions = apiOptions.map((option) => ({
      value: option.id,
      text: option.name
    }));

    const allOptions = [...staticOptions, ...transformedApiOptions];

    return value.map((id) => ({
      value: id,
      text: allOptions.find((option) => option.value === id)?.text || "Unknown"
    }));
  };

  return (
    <>
      <PageMetaTagTitle title="TRG qualifications" />
      <LoadingModal name={MODAL_EXPORT_LOADING} message={t("qam.loading_pdf_report_message")} />
      <SmartTable
        className="table-vertical-top"
        containerClassName="qualifications-table"
        externalActions={
          <>
            <NavLink to={PAGE_TRG_MASS_ASSIGNMENT} className="action-btn">
              <Button type="primary" icon={<i className="ti-check-box" />}>
                {t("trg.qualifications.mass_assignment")}
              </Button>
            </NavLink>
          </>
        }
        clearFiltersActions={
          <Tooltip placement="topLeft" title={t("container.general_words.clear_filters")}>
            <Button onClick={handleClearFilters} icon={<ClearOutlined />}>
              {t("container.general_words.clear_filters")}
            </Button>
          </Tooltip>
        }
        externalFilters={
          <div className="flex qualification-external-filters">
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-10"
              initialValue={contractFilter}
              value={contractFilter?.value}
              placeholder={t("trg.qualifications.filter_by_contract")}
              staticOptions={contractTypeOptions}
              onChange={(value) => handleFilterChange("validContract", value)}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-10"
              initialValue={statusFilter}
              value={statusFilter?.value}
              placeholder={t("trg.qualifications.filter_by_qualification_status")}
              staticOptions={qualificationStatusOptions}
              onChange={(value) => handleFilterChange("qualificationStatus", value)}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              overwritePreviousOptions
              className="mr-10 mb-15"
              showArrow
              value={branchFilter}
              staticOptions={branchesOptions}
              placeholder={t("org.org_chart.filter_by_branch")}
              onChange={(value) => handleFilterChange("branchId", value)}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-15"
              placeholder={t("trg.reporting.filter_by_department")}
              apiRoute="departments?order_by_column%5B%5D=name&order_by_type%5B%5D=asc&with%5B%5D=branch"
              transformer={(values) =>
                values?.map((item) => {
                  const text = item.branch.city ? `${item.name} (${item.branch.city})` : item.name;
                  return { ...item, text };
                }) || []
              }
              value={departmentFilter}
              onChange={(value) => handleFilterChange("departmentId", value)}
            />
            <ResourceSelectLazyLoading
              shouldReset={shouldReset}
              showArrow
              showSearch
              allowClear
              className="mr-10 mb-15"
              placeholder={t("trg.reporting.filter_by_area")}
              apiRoute="trg/areas?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
              value={areaFilter}
              onChange={(value) => handleFilterChange("areaId", value)}
            />
            {!jobTitleState.loading && (
              <ResourceSelectLazyLoading
                shouldReset={shouldReset}
                showArrow
                showSearch
                allowClear
                maxTagCount="responsive"
                mode="multiple"
                className="mr-10 mb-15"
                placeholder={t("trg.reporting.filter_by_job_title")}
                apiRoute="job-titles?order_by_column%5B%5D=name&order_by_type%5B%5D=asc"
                initialValue={prepopulateFilter(
                  localStorageTableFilters.anotherFilters?.find((filter) => filter.nameKey === "jobTitleIds")?.value,
                  [],
                  apiJobTitleOptions
                )}
                value={jobTitleFilter?.value}
                onChange={(value) => handleFilterChange("jobTitleIds", value)}
              />
            )}
            {!employeeStatusState.loading && (
              <ResourceSelectLazyLoading
                shouldReset={shouldReset}
                showArrow
                showSearch
                allowClear
                className="mr-10 mb-10"
                mode="multiple"
                initialValue={prepopulateFilter(
                  localStorageTableFilters.anotherFilters?.find((filter) => filter.nameKey === "employeeStatusIds")
                    ?.value,
                  [
                    { value: "all_employee_status", text: t("trg.qualifications.all_employee_status") },
                    { value: "no_employee_status", text: t("trg.qualifications.no_employee_status") }
                  ],
                  apiEmployeeStatusOptions
                )}
                value={employeeStatusFilter?.value}
                placeholder={t("trg.qualifications.filter_by_employee_status")}
                apiRoute="employee-status"
                maxTagCount="responsive"
                staticOptions={[
                  { value: "all_employee_status", text: t("trg.qualifications.all_employee_status") },
                  { value: "no_employee_status", text: t("trg.qualifications.no_employee_status") }
                ]}
                onChange={(value) => handleFilterChange("employeeStatusIds", value)}
              />
            )}
            <DatePicker
              className="mb-10 mr-10"
              style={{ width: 200, minWidth: 200, maxHeight: 40 }}
              placeholder={t("trg.qualifications.filter_by_date")}
              defaultValue={dateFilter?.value ? moment(dateFilter.value) : undefined}
              onChange={(value) => handleFilterChange("dateFilter", moment(value).format(config.defaultDateFormat))}
            />
          </div>
        }
        customHandleTableChange={customHandleTableChange}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize:
            totalItems > tableService.getFilters().defaultPageSize
              ? totalItems
              : tableService.getFilters().defaultPageSize,
          total:
            totalItems > tableService.getFilters().defaultPageSize
              ? totalItems * Math.ceil(tableService.getTotal() / tableService.defaultPageSize)
              : tableService.getTotal()
        }}
        rowSelection={false}
        scroll={{ x: 800, y: tableHeight - 47 }}
        name={TABLE_QUALIFICATIONS}
        tableService={tableService}
        columns={generateColumns(t, dispatch)}
        route="trg/qualifications"
        tableLocalStorageKeyName={tableKeyName}
        transformer={(data) => {
          const preparedData = prepareQualificationsData(data, t, format, tableService.getFilters());
          setTotalItems(preparedData.length);
          return preparedData;
        }}
        newItemProps={{
          access: true,
          text: t("trg.qualifications.add_qualification"),
          onClick: () => dispatch(openModalAction({ name: MODAL_QUALIFICATION }))
        }}
      />
    </>
  );
};

export default Qualifications;
