import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Form, { FORM_HORIZONTAL_LAYOUT } from "../../../components/Form";
import useFormSubmit from "../../../components/Form/useFormSubmit";
import { reloadTable } from "../../../components/SmartTable/reducer";
import { closeModalAction } from "../../../components/Modal/reducer";
import { FORM_ITEM_TEXT } from "../../../components/Form/FormItems/FormItemText";
import Button from "../../../components/Button";
import { renderDynamicallyFields } from "../../../components/Form/methods";
import { useModalData } from "../../../components/Modal/useModalData";
import { FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING } from "../../../components/Form/FormItems/FormItemResourceSelectLazyLoading";
import { FORM_ITEM_DATE_PICKER } from "../../../components/Form/FormItems/FormItemDatePicker";
import moment from "../../../moment";
import { DATE_SHORT_FORMAT } from "../../../components/DatePicker";
import { FORM_ITEM_RESOURCE_SELECT } from "../../../components/Form/FormItems/FormItemResourceSelect";
import { FORM_TYPE_UPLOAD_FILE } from "../../../components/Form/FormItems/FormItemUploadFile";
import { FORM_ITEM_TEXT_AREA } from "../../../components/Form/FormItems/FormItemTextArea";
import { FORM_ITEM_CHECKBOX } from "../../../components/Form/FormItems/FormItemCheckbox";
import { formTransformer } from "../../Core/Users/UserView/UserViewTrgQualifications/methods";
import { useDateFormat } from "../../../utils/hooks/useDateFormat";
import { TABLE_QUALIFICATIONS } from "./methods";
import { MODAL_QUALIFICATION } from "./index";

const QualificationForm = () => {
  const { format } = useDateFormat();
  const data = useModalData(MODAL_QUALIFICATION);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewRecord = !data?.qualification?.id;
  const [training, setTraining] = useState(data.qualification?.training);
  const { loading, submit, generalError } = useFormSubmit({
    form,
    route: isNewRecord ? "trg/qualifications" : `trg/qualifications/${data?.qualification?.id}`,
    method: isNewRecord ? "post" : "put",
    transformer: (values) => {
      return {
        ...(isNewRecord
          ? {
              ...formTransformer(values, format)
            }
          : values),
        expiry_notification: values.expiry_notification === undefined ? false : values.expiry_notification
      };
    },
    onSuccess: () => {
      dispatch(reloadTable({ name: TABLE_QUALIFICATIONS }));
      localStorage.setItem("qualificationsPageStateApply", true);
      dispatch(closeModalAction({ name: MODAL_QUALIFICATION }));
    }
  });

  return (
    <Form
      onSubmit={submit}
      form={form}
      errors={generalError}
      loading={loading}
      layout={FORM_HORIZONTAL_LAYOUT}
      initialValues={{
        ...data,
        user_id: data.user_detail?.user_id ? data.user_detail.user_id : undefined,
        training_id: data.qualification?.training?.id || undefined,
        description: data.qualification?.description || undefined,
        internal_remarks: data.qualification?.internal_remarks || undefined,
        expiry_notification: !isNewRecord ? data.qualification?.expiry_notification : true
      }}
      header={
        <>
          <div className="h3">
            {t(isNewRecord ? "trg.add_qualification_modal.form_title" : "trg.edit_qualification_modal.form_title")}
          </div>
          <div className="mb-30 mt-10 h6 font-weight-300 text-muted">{t("container.general_words.form_subtitle")}</div>
        </>
      }
    >
      {renderDynamicallyFields([
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "user_id",
          label: "trg.add_qualification_modal.employee",
          placeholder: "trg.add_qualification_modal.employee",
          initialValue: data.user_detail?.user_id
            ? { value: data.user_detail.user_id, text: `${data.name} (${data.user_detail.staff_number})` }
            : undefined,
          apiRoute: "users/all",
          fieldProps: {
            disabled: !isNewRecord,
            transformer: (res) =>
              res.map((user) => ({ ...user, text: `${user.name} (${user.userDetail.staff_number})` })),
            searchColumns: ["name"],
            customParams: {
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["user_is_in_organisation"],
              op: ["eq"],
              term: [1]
            }
          }
        },
        {
          required: true,
          type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
          name: "training_id",
          label: "trg.add_qualification_modal.training",
          placeholder: "trg.add_qualification_modal.training",
          apiRoute: "trg/trainings",
          initialValue: data.qualification?.training
            ? {
                value: data.qualification.training.id,
                text: `${data.qualification.training.name} (${data.qualification.training.code})`
              }
            : undefined,
          fieldProps: {
            searchColumns: ["name", "code"],
            disabled: !isNewRecord,
            onChange: (id, obj) => setTraining(obj),
            customParams: {
              with: ["provider", "validity"],
              order_by_column: ["name"],
              order_by_type: ["asc"],
              field: ["active"],
              op: ["eq"],
              term: [1]
            },
            transformer: (values) => {
              return values.map((item) => ({ ...item, text: `${item.name} (${item.code})` }));
            }
          }
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "description",
          label: "trg.planning_mass_assignment.description",
          placeholder: "trg.planning_mass_assignment.description",
          autoSize: { minRows: 2, maxRows: 6 },
          fieldProps: {
            disabled: !isNewRecord
          }
        },
        {
          type: FORM_ITEM_CHECKBOX,
          name: "expiry_notification",
          label: "trg.add_qualification_modal.expiry_notification",
          visible: training ? !training.validity?.no_expiry : true,
          fieldProps: {
            defaultChecked: true
          }
        },
        {
          type: FORM_ITEM_TEXT_AREA,
          name: "internal_remarks",
          label: "trg.add_qualification_modal.internal_remarks",
          placeholder: "trg.add_qualification_modal.internal_remarks",
          autoSize: { minRows: 2, maxRows: 6 }
        },
        ...(isNewRecord
          ? [
              {
                type: FORM_ITEM_DATE_PICKER,
                required: true,
                dependencies: ["validity_start_date"],
                name: "completed_date",
                label: "trg.add_qualification_modal.completed_date",
                placeholder: "trg.add_qualification_modal.completed_date",
                disabledDate: (current) => {
                  const activeTo = form.getFieldValue("validity_start_date");
                  return activeTo
                    ? moment(current, DATE_SHORT_FORMAT).startOf("day") >
                        moment(activeTo, DATE_SHORT_FORMAT).startOf("day")
                    : false;
                }
              },
              {
                type: FORM_ITEM_DATE_PICKER,
                required: true,
                dependencies: ["completed_date"],
                name: "validity_start_date",
                label: "trg.add_qualification_modal.validity_start_date",
                placeholder: "trg.add_qualification_modal.validity_start_date",
                disabledDate: (current) => {
                  const activeFrom = form.getFieldValue("completed_date");
                  return activeFrom
                    ? moment(current, DATE_SHORT_FORMAT).startOf("day") <
                        moment(activeFrom, DATE_SHORT_FORMAT).startOf("day")
                    : false;
                }
              },
              {
                type: FORM_ITEM_RESOURCE_SELECT,
                required: true,
                name: "instructor_type",
                label: "trg.add_qualification_modal.instructor_type",
                placeholder: "trg.add_qualification_modal.instructor_type",
                options: [
                  {
                    value: 1,
                    text: t("trg.add_qualification_modal.internal")
                  },
                  {
                    value: 0,
                    text: t("trg.add_qualification_modal.external")
                  }
                ]
              },
              {
                required: true,
                type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
                visible: (formInstance) => formInstance.getFieldValue("instructor_type") === 1,
                name: "instructor_id",
                label: "trg.add_qualification_modal.instructor",
                placeholder: "trg.add_qualification_modal.instructor",
                initialValue: data.instructor,
                apiRoute: "users/all",
                fieldProps: {
                  searchColumns: ["name"],
                  customParams: {
                    "filters[validContract]": 1,
                    order_by_column: ["name"],
                    order_by_type: ["asc"],
                    field: ["user_is_in_organisation"],
                    op: ["eq"],
                    term: [1]
                  }
                }
              },
              {
                required: true,
                type: FORM_ITEM_RESOURCE_SELECT_LAZY_LOADING,
                visible: (formInstance) => formInstance.getFieldValue("instructor_type") === 0,
                name: "external_provider_id",
                label: "trg.add_qualification_modal.provider",
                placeholder: "trg.add_qualification_modal.provider",
                apiRoute: "trg/external-providers",
                fieldProps: {
                  requestOnMount: !!data.external_provider_id,
                  customParams: {
                    order_by_column: ["name"],
                    order_by_type: ["asc"],
                    field: ["active"],
                    op: ["eq"],
                    term: [1]
                  }
                }
              },
              {
                required: true,
                type: FORM_ITEM_TEXT,
                visible: (formInstance) => formInstance.getFieldValue("instructor_type") === 0,
                max: 200,
                name: "instructor_name",
                label: "trg.add_qualification_modal.instructor",
                placeholder: "trg.add_qualification_modal.instructor"
              },
              {
                type: FORM_TYPE_UPLOAD_FILE,
                name: "evidence",
                label: "trg.add_qualification_modal.evidence",
                placeholder: "ior.new_report.new_attachment.attachment_button",
                fieldProps: {
                  purpose: "qualificationHistory",
                  entity: "qualificationHistory",
                  type: "document"
                }
              },
              {
                type: FORM_TYPE_UPLOAD_FILE,
                name: "certificate",
                label: "trg.add_qualification_modal.certificate",
                placeholder: "ior.new_report.new_attachment.attachment_button",
                fieldProps: {
                  purpose: "certificate",
                  entity: "certificate",
                  type: "document"
                }
              }
            ]
          : [])
      ])}
      <div className="flex mt-25 flex-justify-end">
        <Button
          className="min-w-150"
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          icon={<i className="ti-check" />}
        >
          {t("container.general_words.save")}
        </Button>
      </div>
    </Form>
  );
};

export default QualificationForm;
