import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import CheckToken from "../../components/_auth/CheckToken";
import loadable from "../../utils/loadable";
import Layout from "../../components/_layout";
import {
  PAGE_EDIT_ORGANIZATION_STAFF,
  PAGE_NEW_ORGANIZATION_STAFF,
  PAGE_ORGANIZATION_CHART,
  PAGE_ORGANIZATION_SETTINGS,
  PAGE_ORGANIZATION_STAFF
} from "../Organization/routes";
import {
  PAGE_ANONYMOUS_REPORT,
  PAGE_AUDIT_LOG,
  PAGE_DASHBOARD,
  PAGE_EDIT_GROUP,
  PAGE_EDIT_USER,
  PAGE_FORGOT_PASSWORD,
  PAGE_GROUPS,
  PAGE_LOGIN,
  PAGE_MAIN_SETTINGS,
  PAGE_NEW_GROUP,
  PAGE_NEW_USER,
  PAGE_NOT_FOUND,
  PAGE_NOTIFICATIONS,
  PAGE_RESET_PASSWORD,
  PAGE_TWO_FACTOR,
  PAGE_TWO_FACTOR_QR,
  PAGE_USERS,
  PAGE_VIEW_USER
} from "../Core/routes";
import {
  PERMISSION_CREATE_IOR_REPORTS,
  PERMISSION_MANAGE_ADM,
  PERMISSION_MANAGE_CORE_GROUPS,
  PERMISSION_MANAGE_CORE_SETTINGS,
  PERMISSION_MANAGE_CORE_USERS,
  PERMISSION_MANAGE_IOR_REPORTS,
  PERMISSION_MANAGE_ORG_STAFF,
  PERMISSION_MANAGE_QAM_AUDITEES,
  PERMISSION_MANAGE_QAM_AUDITORS,
  PERMISSION_MANAGE_RSM_PUBLICATIONS,
  PERMISSION_MANAGE_TRG,
  PERMISSION_MANAGE_TRG_PLANNING,
  PERMISSION_VIEW_ADM,
  PERMISSION_VIEW_CORE_AUDIT_LOG,
  PERMISSION_VIEW_CORE_GROUPS,
  PERMISSION_VIEW_CORE_NOTIFICATIONS,
  PERMISSION_VIEW_CORE_USERS,
  PERMISSION_VIEW_IOR_ALL_REPORTS,
  PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS,
  PERMISSION_VIEW_IOR_REPORTS,
  PERMISSION_VIEW_IOR_SETTINGS,
  PERMISSION_VIEW_ORG_CHART,
  PERMISSION_VIEW_ORG_SETTINGS,
  PERMISSION_VIEW_ORG_STAFF,
  PERMISSION_VIEW_QAM_AUDITEES,
  PERMISSION_VIEW_QAM_AUDITORS,
  PERMISSION_VIEW_QAM_AUDITS,
  PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS,
  PERMISSION_VIEW_QAM_DASHBOARD,
  PERMISSION_VIEW_QAM_ENTITIES,
  PERMISSION_VIEW_QAM_NORMS,
  PERMISSION_VIEW_QAM_SETTINGS,
  PERMISSION_VIEW_RSM_PUBLICATIONS,
  PERMISSION_VIEW_RSM_REPORTING,
  PERMISSION_VIEW_RSM_SETTINGS,
  PERMISSION_VIEW_TRG_PLANNING,
  PERMISSION_VIEW_TRG_SETTINGS,
  PERMISSION_VIEW_IOR_ASSIGNEES,
  PERMISSION_MANAGE_IOR_ASSIGNEES,
  PERMISSION_VIEW_IOR_INVESTIGATORS,
  PERMISSION_MANAGE_IOR_INVESTIGATORS,
  PERMISSION_VIEW_DOM_DASHBOARD,
  PERMISSION_VIEW_DOM_DOCUMENTS,
  PERMISSION_VIEW_DOM_ENTITIES,
  PERMISSION_VIEW_DOM_SETTINGS,
  PERMISSION_VIEW_DOM_RESPONSIBLE_PERSONS,
  PERMISSION_VIEW_DOM_APPROVERS,
  PERMISSION_MANAGE_DOM_APPROVERS,
  PERMISSION_MANAGE_DOM_RESPONSIBLE_PERSONS,
  PERMISSION_VIEW_DOM_TEMPLATES,
  PERMISSION_MANAGE_DOM_TEMPLATES
} from "../../components/_auth/permissions/permissions";
import {
  PAGE_QAM_ACTIVITIES,
  PAGE_QAM_ACTIVITIES_CALENDAR,
  PAGE_QAM_ACTIVITY_ACTIONS,
  PAGE_QAM_ACTIVITY_CHECKLIST,
  PAGE_QAM_ACTIVITY_PROGRAM,
  PAGE_QAM_AUDITEES,
  PAGE_QAM_AUDITORS,
  PAGE_QAM_CATALOGUES,
  PAGE_QAM_CORRECTIVE_ACTION,
  PAGE_QAM_CORRECTIVE_ACTIONS,
  PAGE_QAM_DASHBOARD,
  PAGE_QAM_EDIT_AUDITEE,
  PAGE_QAM_EDIT_AUDITOR,
  PAGE_QAM_ENTITIES,
  PAGE_QAM_NEW_AUDITEE,
  PAGE_QAM_NEW_AUDITOR,
  PAGE_QAM_SETTINGS
} from "../Qam/routes";
import {
  USER_TYPE_AUDITEE,
  USER_TYPE_AUDITOR,
  USER_TYPE_STAFF,
  USER_TYPE_USER,
  USER_TYPE_ASSIGNEE,
  USER_TYPE_INVESTIGATOR,
  USER_TYPE_APPROVER,
  USER_TYPE_RESPONSIBLE_PERSON
} from "../Core/Users/User/config";
import { ACTIVITY_ACTION, ACTIVITY_CHECKLIST, ACTIVITY_PROGRAM } from "../Qam/ActivityView/config";
import {
  PAGE_RSM_REPORTING,
  PAGE_RSM_PUBLICATIONS,
  PAGE_RSM_MY_PUBLICATIONS,
  PAGE_RSM_SETTINGS,
  PAGE_RSM_VIEW_MY_PUBLICATION,
  PAGE_RSM_VIEW_PUBLICATION,
  PAGE_RSM_NEW_PUBLICATION,
  PAGE_RSM_EDIT_PUBLICATION,
  PAGE_RSM_DASHBOARD
} from "../Rsm/routes";
import {
  PAGE_IOR_MY_REPORTS,
  PAGE_IOR_REPORTS,
  PAGE_IOR_SETTINGS,
  PAGE_IOR_DASHBOARD,
  PAGE_IOR_VIEW_REPORT,
  PAGE_IOR_EDIT_REPORT,
  PAGE_IOR_NEW_REPORT,
  PAGE_IOR_ASSIGNEES,
  PAGE_IOR_NEW_ASSIGNEE,
  PAGE_IOR_EDIT_ASSIGNEE,
  PAGE_IOR_INVESTIGATORS,
  PAGE_IOR_NEW_INVESTIGATOR,
  PAGE_IOR_EDIT_INVESTIGATOR,
  PAGE_IOR_CORRECTIVE_ACTIONS,
  PAGE_IOR_CORRECTIVE_ACTION
} from "../Ior/routes";
import {
  PAGE_TRG_DASHBOARD,
  PAGE_TRG_EDIT_PLANNING,
  PAGE_TRG_EXTERNAL_PROVIDERS,
  PAGE_TRG_MASS_ASSIGNMENT,
  PAGE_TRG_NEW_PLANNING,
  PAGE_TRG_PLANNING,
  PAGE_TRG_PLANNING_CALENDAR,
  PAGE_TRG_QUALIFICATIONS,
  PAGE_TRG_REPORTING,
  PAGE_TRG_SETTINGS,
  PAGE_TRG_TRAINING_MATRIX,
  PAGE_TRG_TRAINING_PROGRAM,
  PAGE_TRG_TRAININGS,
  PAGE_TRG_VIEW_PLANNING
} from "../Trg/routes";
import { REPORT_FORM_TYPE } from "../Ior/ReportPage";
import {
  PAGE_ADM_DRAFTS,
  PAGE_ADM_EDIT_REPORT,
  PAGE_ADM_EXPORTS,
  PAGE_ADM_NEW_REPORT,
  PAGE_ADM_REPORTS,
  PAGE_ADM_REPORT
} from "../Adm/routes";
import {
  PAGE_DOM_APPROVERS,
  PAGE_DOM_DASHBOARD,
  PAGE_DOM_TEMPLATES,
  PAGE_DOM_DOCUMENTS,
  PAGE_DOM_EDIT_APPROVER,
  PAGE_DOM_EDIT_RESPONSIBLE_PERSON,
  PAGE_DOM_ENTITIES,
  PAGE_DOM_NEW_APPROVER,
  PAGE_DOM_NEW_RESPONSIBLE_PERSON,
  PAGE_DOM_RESPONSIBLE_PERSONS,
  PAGE_DOM_SETTINGS,
  PAGE_DOM_VIEW_TEMPLATE_HISTORY,
  PAGE_DOM_EDIT_HISTORY_TEMPLATE,
  PAGE_DOM_DOCUMENT,
  PAGE_DOM_VIEW_TASK,
  PAGE_DOM_HISTORY_DOCUMENT,
  PAGE_DOM_DOCUMENTS_MASS_DUPLICATION,
  PAGE_DOM_VIEW_KEYWORD_TASK
} from "../Dom/routes";
import { SystemStatusProvider } from "../../context/SystemStatusContext";
import AuthenticatedRoute from "./RouterRoutes/AuthenticatedRoute";
import UnauthenticatedRoute from "./RouterRoutes/UnauthenticatedRoute";
import PublicRoute from "./RouterRoutes/PublicRoute";

const LoginPage = loadable(() => import("../Core/Login"));
const TwoFactorPage = loadable(() => import("../Core/TwoFactor"));
const TwoFactorQr = loadable(() => import("../Core/TwoFactorQr"));
const DashboardPage = loadable(() => import("../Core/Dashboard"));
const NotFoundPage = loadable(() => import("../Core/NotFound"));
const ForgotPasswordPage = loadable(() => import("../Core/ForgotPassword"));
const ResetPasswordPage = loadable(() => import("../Core/ResetPassword"));
const OrganizationChart = loadable(() => import("../Organization/OrganizationChart"));
const OrganizationStaff = loadable(() => import("../Organization/OrganizationStaff"));
const OrganizationSettings = loadable(() => import("../Organization/OrgSettings"));
const AnonymousReport = loadable(() => import("../Core/AnonymousReport"));
const Users = loadable(() => import("../Core/Users"));
const User = loadable(() => import("../Core/Users/User"));
const UserView = loadable(() => import("../Core/Users/UserView"));
const Groups = loadable(() => import("../Core/Groups"));
const Group = loadable(() => import("../Core/Groups/Group"));
const MainSettings = loadable(() => import("../Core/MainSettings"));
const Notifications = loadable(() => import("../Core/Notifications"));
const AuditLog = loadable(() => import("../Core/AuditLog"));
const Activities = loadable(() => import("../Qam/Activities"));
const ActivitiesCalendar = loadable(() => import("../Qam/ActivitiesCalendar"));
const Auditors = loadable(() => import("../Qam/Auditors"));
const Auditees = loadable(() => import("../Qam/Auditees"));
const QamSettings = loadable(() => import("../Qam/QamSettings"));
const QamDashboard = loadable(() => import("../Qam/Dashboard"));
const Entities = loadable(() => import("../Qam/Entities"));
const CorrectiveActions = loadable(() => import("../Qam/CorrectiveActions"));
const CorrectiveActionView = loadable(() => import("../Qam/CorrectiveActionView"));
const IorCorrectiveActionView = loadable(() => import("../Ior/CorrectiveActionView"));
const ActivityView = loadable(() => import("../Qam/ActivityView"));
const Catalogues = loadable(() => import("../Qam/Catalogues"));
const TrgSettings = loadable(() => import("../Trg/TrgSettings"));
const ExternalProviders = loadable(() => import("../Trg/ExternalProviders"));
const Trainings = loadable(() => import("../Trg/Trainings"));
const TrainingMatrix = loadable(() => import("../Trg/TrainingMatrix"));
const TrainingProgram = loadable(() => import("../Trg/TrainingProgram"));
const TrgReporting = loadable(() => import("../Trg/TrgReporting"));
const TrgPlanning = loadable(() => import("../Trg/TrgPlanning"));
const TrgPlanningCalendar = loadable(() => import("../Trg/TrgPlanningCalendar"));
const TrgPlanningForm = loadable(() => import("../Trg/TrgPlanning/PlanningForm"));
const TrgPlanningView = loadable(() => import("../Trg/TrgPlanning/PlanningView"));
const TrgQualifications = loadable(() => import("../Trg/TrgQualifications"));
const TrgMassAssignment = loadable(() => import("../Trg/TrgMassAssignment"));
const TrgDashboard = loadable(() => import("../Trg/Dashboard"));
const MyPublications = loadable(() => import("../Rsm/MyPublications"));
const Publications = loadable(() => import("../Rsm/Publications"));
const Reporting = loadable(() => import("../Rsm/Reporting"));
const RsmSettings = loadable(() => import("../Rsm/RsmSettings"));
const MyPublicationView = loadable(() => import("../Rsm/MyPublicationView"));
const PublicationView = loadable(() => import("../Rsm/PublicationView"));
const PublicationForm = loadable(() => import("../Rsm/Publications/PublicationForm"));
const RsmDashboard = loadable(() => import("../Rsm/Dashboard"));
const IorSettings = loadable(() => import("../Ior/IorSettings"));
const MyReports = loadable(() => import("../Ior/MyReports"));
const IorDashboard = loadable(() => import("../Ior/Dashboard"));
const IorReports = loadable(() => import("../Ior/Reports"));
const IorReportPage = loadable(() => import("../Ior/ReportPage"));
const IorAssignees = loadable(() => import("../Ior/Assignees"));
const IorInvestigators = loadable(() => import("../Ior/Investigators"));
const IorCorrectiveActions = loadable(() => import("../Ior/CorrectiveActions"));
const AdmReportsPage = loadable(() => import("../Adm/Reports"));
const AdmExportsPage = loadable(() => import("../Adm/Exports"));
const AdmDraftsPage = loadable(() => import("../Adm/Drafts"));
const AdmReportFormPage = loadable(() => import("../Adm/ReportForm"));
const AdmReportView = loadable(() => import("../Adm/ReportView"));
const DomDashboard = loadable(() => import("../Dom/Dashboard"));
const DomDocuments = loadable(() => import("../Dom/Documents"));
const DomEntities = loadable(() => import("../Dom/DomEntities"));
const DomSettings = loadable(() => import("../Dom/DomSettings"));
const DomResponsiblePersons = loadable(() => import("../Dom/ResponsiblePersons"));
const DomApprovers = loadable(() => import("../Dom/Approvers"));
const DomDocumentTemplates = loadable(() => import("../Dom/DocumentTemplates"));
const DomDocumentTemplateHistory = loadable(() => import("../Dom/DocumentTemplateHistory"));
const DomDocumentTemplate = loadable(() => import("../Dom/DocumentTemplate"));
const DomDocumentView = loadable(() => import("../Dom/DocumentView"));
const DomDocumentTaskView = loadable(() => import("../Dom/DocumentTaskView"));
const DomDocumentKeywordTaskView = loadable(() => import("../Dom/DocumentKeywordTaskView"));
const DomDocumentHistory = loadable(() => import("../Dom/DocumentHistory"));
const DomDocumentMassDuplication = loadable(() => import("../Dom/DocumentMassDuplication"));

const App = () => {
  return (
    <CheckToken>
      <Router>
        <Switch>
          {/* Unauthenticated pages */}
          <UnauthenticatedRoute path={PAGE_LOGIN}>
            <LoginPage />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute path={PAGE_TWO_FACTOR}>
            <TwoFactorPage />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute path={PAGE_TWO_FACTOR_QR}>
            <TwoFactorQr />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute path={PAGE_FORGOT_PASSWORD}>
            <ForgotPasswordPage />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute path={PAGE_RESET_PASSWORD}>
            <ResetPasswordPage />
          </UnauthenticatedRoute>

          {/* Public pages */}
          <PublicRoute path={PAGE_NOT_FOUND} component={NotFoundPage} />

          {/* Authenticated pages including layout */}
          <Route>
            <SystemStatusProvider>
              <Layout>
                <Switch>
                  {/* Core Routes */}
                  <AuthenticatedRoute exact path={PAGE_DASHBOARD}>
                    <DashboardPage />
                  </AuthenticatedRoute>
                  <PublicRoute exact path={PAGE_ANONYMOUS_REPORT}>
                    <AnonymousReport />
                  </PublicRoute>
                  <AuthenticatedRoute exact path={PAGE_USERS} gates={PERMISSION_VIEW_CORE_USERS}>
                    <Users />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_NEW_USER} gates={PERMISSION_MANAGE_CORE_USERS}>
                    <User type={USER_TYPE_USER} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_EDIT_USER}/:id`} gates={PERMISSION_MANAGE_CORE_USERS}>
                    <User type={USER_TYPE_USER} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_VIEW_USER}/:id`} gates={PERMISSION_MANAGE_CORE_USERS}>
                    <UserView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_GROUPS} gates={PERMISSION_VIEW_CORE_GROUPS}>
                    <Groups />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_NEW_GROUP} gates={PERMISSION_MANAGE_CORE_GROUPS}>
                    <Group />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_EDIT_GROUP}/:id`} gates={PERMISSION_MANAGE_CORE_GROUPS}>
                    <Group />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_MAIN_SETTINGS} gates={PERMISSION_MANAGE_CORE_SETTINGS}>
                    <MainSettings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_NOTIFICATIONS} gates={PERMISSION_VIEW_CORE_NOTIFICATIONS}>
                    <Notifications />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_AUDIT_LOG} gates={PERMISSION_VIEW_CORE_AUDIT_LOG}>
                    <AuditLog />
                  </AuthenticatedRoute>
                  {/* Organization Routes  */}
                  <AuthenticatedRoute exact path={PAGE_ORGANIZATION_CHART} gates={PERMISSION_VIEW_ORG_CHART}>
                    <OrganizationChart />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_ORGANIZATION_STAFF} gates={PERMISSION_VIEW_ORG_STAFF}>
                    <OrganizationStaff />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_NEW_ORGANIZATION_STAFF} gates={PERMISSION_MANAGE_ORG_STAFF}>
                    <User type={USER_TYPE_STAFF} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_EDIT_ORGANIZATION_STAFF}/:id`}
                    gates={PERMISSION_MANAGE_ORG_STAFF}
                  >
                    <User type={USER_TYPE_STAFF} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_ORGANIZATION_SETTINGS} gates={PERMISSION_VIEW_ORG_SETTINGS}>
                    <OrganizationSettings />
                  </AuthenticatedRoute>
                  {/* Dom Routes  */}
                  <AuthenticatedRoute exact path={PAGE_DOM_DASHBOARD} gates={PERMISSION_VIEW_DOM_DASHBOARD}>
                    <DomDashboard />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_DOM_DOCUMENTS} gates={PERMISSION_VIEW_DOM_DOCUMENTS}>
                    <DomDocuments />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_DOM_ENTITIES} gates={PERMISSION_VIEW_DOM_ENTITIES}>
                    <DomEntities />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_DOM_APPROVERS} gates={PERMISSION_VIEW_DOM_APPROVERS}>
                    <DomApprovers />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_DOM_RESPONSIBLE_PERSONS}
                    gates={PERMISSION_VIEW_DOM_RESPONSIBLE_PERSONS}
                  >
                    <DomResponsiblePersons />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_DOM_SETTINGS} gates={PERMISSION_VIEW_DOM_SETTINGS}>
                    <DomSettings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_DOM_NEW_APPROVER} gates={PERMISSION_MANAGE_DOM_APPROVERS}>
                    <User type={USER_TYPE_APPROVER} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_DOM_EDIT_APPROVER}/:id`}
                    gates={PERMISSION_MANAGE_DOM_APPROVERS}
                  >
                    <User type={USER_TYPE_APPROVER} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_DOM_NEW_RESPONSIBLE_PERSON}
                    gates={PERMISSION_MANAGE_DOM_RESPONSIBLE_PERSONS}
                  >
                    <User type={USER_TYPE_RESPONSIBLE_PERSON} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_DOM_EDIT_RESPONSIBLE_PERSON}/:id`}
                    gates={PERMISSION_MANAGE_DOM_RESPONSIBLE_PERSONS}
                  >
                    <User type={USER_TYPE_RESPONSIBLE_PERSON} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_DOM_TEMPLATES} gates={PERMISSION_VIEW_DOM_TEMPLATES}>
                    <DomDocumentTemplates />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_DOM_VIEW_TEMPLATE_HISTORY}/:id`}
                    gates={PERMISSION_VIEW_DOM_TEMPLATES}
                  >
                    <DomDocumentTemplateHistory />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_DOM_EDIT_HISTORY_TEMPLATE}/:id`}
                    gates={PERMISSION_MANAGE_DOM_TEMPLATES}
                  >
                    <DomDocumentTemplate />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_DOM_DOCUMENT}/:id`} gates={PERMISSION_VIEW_DOM_DOCUMENTS}>
                    <DomDocumentView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_DOM_VIEW_TASK}/:id`} gates={PERMISSION_VIEW_DOM_DOCUMENTS}>
                    <DomDocumentTaskView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_DOM_VIEW_KEYWORD_TASK}/:id`}
                    gates={PERMISSION_VIEW_DOM_DOCUMENTS}
                  >
                    <DomDocumentKeywordTaskView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_DOM_HISTORY_DOCUMENT}/:id`}
                    gates={PERMISSION_VIEW_DOM_DOCUMENTS}
                  >
                    <DomDocumentHistory />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_DOM_DOCUMENTS_MASS_DUPLICATION}
                    gates={PERMISSION_VIEW_DOM_DOCUMENTS}
                  >
                    <DomDocumentMassDuplication />
                  </AuthenticatedRoute>
                  {/* Qam Routes  */}
                  <AuthenticatedRoute exact path={PAGE_QAM_DASHBOARD} gates={PERMISSION_VIEW_QAM_DASHBOARD}>
                    <QamDashboard />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_ACTIVITIES} gates={PERMISSION_VIEW_QAM_AUDITS}>
                    <Activities />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_QAM_ACTIVITY_PROGRAM}/:id`}
                    gates={PERMISSION_VIEW_QAM_AUDITS}
                  >
                    <ActivityView viewType={ACTIVITY_PROGRAM} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_QAM_ACTIVITY_CHECKLIST}/:id`}
                    gates={PERMISSION_VIEW_QAM_AUDITS}
                  >
                    <ActivityView viewType={ACTIVITY_CHECKLIST} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_QAM_ACTIVITY_ACTIONS}/:id`}
                    gates={PERMISSION_VIEW_QAM_AUDITS}
                  >
                    <ActivityView viewType={ACTIVITY_ACTION} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_ACTIVITIES_CALENDAR} gates={PERMISSION_VIEW_QAM_AUDITS}>
                    <ActivitiesCalendar />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_AUDITORS} gates={PERMISSION_VIEW_QAM_AUDITORS}>
                    <Auditors />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_NEW_AUDITOR} gates={PERMISSION_MANAGE_QAM_AUDITORS}>
                    <User type={USER_TYPE_AUDITOR} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_QAM_EDIT_AUDITOR}/:id`}
                    gates={PERMISSION_MANAGE_QAM_AUDITORS}
                  >
                    <User type={USER_TYPE_AUDITOR} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_AUDITEES} gates={PERMISSION_VIEW_QAM_AUDITEES}>
                    <Auditees />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_NEW_AUDITEE} gates={PERMISSION_MANAGE_QAM_AUDITEES}>
                    <User type={USER_TYPE_AUDITEE} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_QAM_EDIT_AUDITEE}/:id`}
                    gates={PERMISSION_MANAGE_QAM_AUDITEES}
                  >
                    <User type={USER_TYPE_AUDITEE} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_SETTINGS} gates={PERMISSION_VIEW_QAM_SETTINGS}>
                    <QamSettings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_ENTITIES} gates={PERMISSION_VIEW_QAM_ENTITIES}>
                    <Entities />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_QAM_CATALOGUES} gates={PERMISSION_VIEW_QAM_NORMS}>
                    <Catalogues />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_QAM_CORRECTIVE_ACTIONS}
                    gates={PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS}
                  >
                    <CorrectiveActions />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_QAM_CORRECTIVE_ACTION}/:id`}
                    gates={PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS}
                  >
                    <CorrectiveActionView />
                  </AuthenticatedRoute>
                  {/* Trg Routes  */}
                  <AuthenticatedRoute exact path={PAGE_TRG_SETTINGS} gates={PERMISSION_VIEW_TRG_SETTINGS}>
                    <TrgSettings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_EXTERNAL_PROVIDERS} gates={PERMISSION_MANAGE_TRG}>
                    <ExternalProviders />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_TRAININGS} gates={PERMISSION_MANAGE_TRG}>
                    <Trainings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_TRAINING_MATRIX} gates={PERMISSION_MANAGE_TRG}>
                    <TrainingMatrix />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_TRAINING_PROGRAM} gates={PERMISSION_MANAGE_TRG}>
                    <TrainingProgram />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_REPORTING} gates={PERMISSION_MANAGE_TRG}>
                    <TrgReporting />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_PLANNING} gates={PERMISSION_VIEW_TRG_PLANNING}>
                    <TrgPlanning />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_PLANNING_CALENDAR} gates={PERMISSION_VIEW_TRG_PLANNING}>
                    <TrgPlanningCalendar />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_NEW_PLANNING} gates={PERMISSION_MANAGE_TRG_PLANNING}>
                    <TrgPlanningForm />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_TRG_EDIT_PLANNING}/:id`}
                    gates={PERMISSION_MANAGE_TRG_PLANNING}
                  >
                    <TrgPlanningForm />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_TRG_VIEW_PLANNING}/:id`} gates={PERMISSION_VIEW_TRG_PLANNING}>
                    <TrgPlanningView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_QUALIFICATIONS} gates={PERMISSION_MANAGE_TRG}>
                    <TrgQualifications />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_MASS_ASSIGNMENT} gates={PERMISSION_MANAGE_TRG}>
                    <TrgMassAssignment />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_TRG_DASHBOARD} gates={PERMISSION_MANAGE_TRG}>
                    <TrgDashboard />
                  </AuthenticatedRoute>
                  {/* Rsm Routes  */}
                  <AuthenticatedRoute exact path={PAGE_RSM_DASHBOARD} gates={PERMISSION_VIEW_RSM_PUBLICATIONS}>
                    <RsmDashboard />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_RSM_REPORTING} gates={PERMISSION_VIEW_RSM_REPORTING}>
                    <Reporting />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_RSM_EDIT_PUBLICATION}/:id`}
                    gates={PERMISSION_MANAGE_RSM_PUBLICATIONS}
                  >
                    <PublicationForm />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_RSM_NEW_PUBLICATION} gates={PERMISSION_MANAGE_RSM_PUBLICATIONS}>
                    <PublicationForm />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_RSM_PUBLICATIONS} gates={PERMISSION_MANAGE_RSM_PUBLICATIONS}>
                    <Publications />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_RSM_MY_PUBLICATIONS} gates={PERMISSION_VIEW_RSM_PUBLICATIONS}>
                    <MyPublications />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_RSM_SETTINGS} gates={PERMISSION_VIEW_RSM_SETTINGS}>
                    <RsmSettings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_RSM_VIEW_MY_PUBLICATION}/:id`}
                    gates={PERMISSION_VIEW_RSM_PUBLICATIONS}
                  >
                    <MyPublicationView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_RSM_VIEW_PUBLICATION}/:id`}
                    gates={PERMISSION_MANAGE_RSM_PUBLICATIONS}
                  >
                    <PublicationView />
                  </AuthenticatedRoute>
                  {/*Ior routes*/}
                  <AuthenticatedRoute exact path={PAGE_IOR_SETTINGS} gates={PERMISSION_VIEW_IOR_SETTINGS}>
                    <IorSettings />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_MY_REPORTS} gates={PERMISSION_CREATE_IOR_REPORTS}>
                    <MyReports />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_DASHBOARD} gates={PERMISSION_VIEW_IOR_REPORTS}>
                    <IorDashboard />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_IOR_REPORTS}
                    gates={[PERMISSION_MANAGE_IOR_REPORTS, PERMISSION_VIEW_IOR_ALL_REPORTS]}
                  >
                    <IorReports />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_IOR_VIEW_REPORT}/:id`} gates={PERMISSION_VIEW_IOR_REPORTS}>
                    <IorReportPage type={REPORT_FORM_TYPE.VIEW} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_IOR_EDIT_REPORT}/:id`}>
                    <IorReportPage type={REPORT_FORM_TYPE.EDIT} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_NEW_REPORT} gates={PERMISSION_CREATE_IOR_REPORTS}>
                    <IorReportPage type={REPORT_FORM_TYPE.NEW} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_ASSIGNEES} gates={PERMISSION_VIEW_IOR_ASSIGNEES}>
                    <IorAssignees />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_NEW_ASSIGNEE} gates={PERMISSION_MANAGE_IOR_ASSIGNEES}>
                    <User type={USER_TYPE_ASSIGNEE} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_IOR_EDIT_ASSIGNEE}/:id`}
                    gates={PERMISSION_MANAGE_IOR_ASSIGNEES}
                  >
                    <User type={USER_TYPE_ASSIGNEE} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_INVESTIGATORS} gates={PERMISSION_VIEW_IOR_INVESTIGATORS}>
                    <IorInvestigators />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_IOR_NEW_INVESTIGATOR}
                    gates={PERMISSION_MANAGE_IOR_INVESTIGATORS}
                  >
                    <User type={USER_TYPE_INVESTIGATOR} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_IOR_EDIT_INVESTIGATOR}/:id`}
                    gates={PERMISSION_MANAGE_IOR_INVESTIGATORS}
                  >
                    <User type={USER_TYPE_INVESTIGATOR} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_IOR_CORRECTIVE_ACTIONS}
                    gates={PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS}
                  >
                    <IorCorrectiveActions />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_IOR_CORRECTIVE_ACTION}/:id`}
                    gates={PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS}
                  >
                    <IorCorrectiveActionView />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_IOR_INVESTIGATORS} gates={PERMISSION_VIEW_IOR_INVESTIGATORS}>
                    <IorInvestigators />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={PAGE_IOR_NEW_INVESTIGATOR}
                    gates={PERMISSION_MANAGE_IOR_INVESTIGATORS}
                  >
                    <User type={USER_TYPE_INVESTIGATOR} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    exact
                    path={`${PAGE_IOR_EDIT_INVESTIGATOR}/:id`}
                    gates={PERMISSION_MANAGE_IOR_INVESTIGATORS}
                  >
                    <User type={USER_TYPE_INVESTIGATOR} />
                  </AuthenticatedRoute>
                  {/*Adm routes*/}
                  <AuthenticatedRoute exact path={PAGE_ADM_REPORTS} gates={PERMISSION_VIEW_ADM}>
                    <AdmReportsPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_ADM_EXPORTS} gates={PERMISSION_VIEW_ADM}>
                    <AdmExportsPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_ADM_DRAFTS} gates={PERMISSION_MANAGE_ADM}>
                    <AdmDraftsPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_ADM_EDIT_REPORT}/:id`} gates={PERMISSION_MANAGE_ADM}>
                    <AdmReportFormPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={PAGE_ADM_NEW_REPORT} gates={PERMISSION_MANAGE_ADM}>
                    <AdmReportFormPage />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path={`${PAGE_ADM_REPORT}/:id`} gates={PERMISSION_VIEW_ADM}>
                    <AdmReportView />
                  </AuthenticatedRoute>
                  {/* 404 Not found page */}
                  <Redirect from="*" to={PAGE_NOT_FOUND} />
                </Switch>
              </Layout>
            </SystemStatusProvider>
          </Route>
        </Switch>
      </Router>
    </CheckToken>
  );
};

export default App;
