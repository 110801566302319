import { Layout } from "antd";
import NavigationMenu from "./NavigationMenu";
import Logo from "./Logo";

/**
 * Sidebar
 * @function Sidebar
 * @description Sidebar component
 */
const Sidebar = ({ collapsed, handleCollapse, marginStatusBar }) => {
  const stylesWhenStatusBar = {
    sider: { paddingTop: marginStatusBar ?? "inherit" },
    container: marginStatusBar ? { display: "flex", flexDirection: "column", height: "100%" } : {},
    menuWrapper: marginStatusBar
      ? { flexGrow: 1, overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none" }
      : {}
  };

  return (
    <Layout.Sider
      className="layout-sidebar"
      trigger={null}
      collapsible
      onCollapse={handleCollapse}
      collapsed={collapsed}
      width={240}
      collapsedWidth={60}
      style={stylesWhenStatusBar.sider}
    >
      <div className="layout-sidebar-navigation-container" style={stylesWhenStatusBar.container}>
        <div className="app-logo">
          <Logo />
        </div>
        <div style={stylesWhenStatusBar.menuWrapper}>
          <NavigationMenu collapsed={collapsed} />
        </div>
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
