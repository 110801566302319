import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SystemStatusContext } from "../../context/SystemStatusContext";

const StatusBar = ({ setCountSystemStatuses }) => {
  const systemStatusesContext = useContext(SystemStatusContext);
  const systemStatuses = systemStatusesContext.statuses;
  const location = useLocation();
  const moduleKeys = new Set(Object.keys(systemStatuses));

  const specialRoutes = {
    "/": "Dashboard",
    "/audit-log": "AuditLog"
  };

  let activeModule =
    specialRoutes[location.pathname] ??
    [...moduleKeys].find((key) => location.pathname.includes(`/${key.toLowerCase()}`) && key !== "Dashboard");

  const routeToModuleMap = {
    "-settings": "Settings",
    "/groups": "UsersGroups",
    "/users": "UsersGroups",
    "/trg/": "TRAINING"
  };

  const matchedModule = Object.entries(routeToModuleMap).find(
    ([path, module]) => location.pathname.includes(path) && moduleKeys.has(module)
  );

  if (matchedModule) {
    activeModule = matchedModule[1];
  }

  const [moduleStatuses, setModuleStatuses] = useState([]);

  useEffect(() => {
    setModuleStatuses(activeModule ? systemStatuses[activeModule] ?? [] : []);
  }, [activeModule, systemStatuses]);

  useEffect(() => {
    setCountSystemStatuses(moduleStatuses.length);
  }, [moduleStatuses, setCountSystemStatuses]);

  if (!moduleStatuses.length) return null;

  return (
    <div style={{ width: "100%", zIndex: 1000, position: "fixed" }}>
      {moduleStatuses.map(({ text, color, text_color }, index) => (
        <div
          key={index}
          style={{
            backgroundColor: `#${color}`,
            color: `#${text_color}`,
            padding: "5px 10px",
            borderRadius: "1px",
            fontSize: "14px",
            marginTop: index ? "1px" : "inherit"
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default StatusBar;
