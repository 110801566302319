import { createContext, useEffect, useState } from "react";
import axios from "../axios";

export const SystemStatusContext = createContext(undefined);

export const SystemStatusProvider = ({ children }) => {
  const [statuses, setStatuses] = useState({});

  const fetchStatuses = () => {
    axios
      .get("system-statuses")
      .then((response) => setStatuses(response.data))
      .catch((error) => console.error("Error fetching statuses:", error));
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  return <SystemStatusContext.Provider value={{ statuses, fetchStatuses }}>{children}</SystemStatusContext.Provider>;
};
