import axios from "../../../axios";
import { openGeneralErrorNotification } from "../../../components/Notification";

export const prepareChartData = (data = []) => {
  const formattedData = {
    treeData: {},
    multipleRoots: false
  };

  if (data.length > 0) {
    if (data.length === 1) {
      formattedData.treeData = data[0];
      formattedData.multipleRoots = false;
    } else {
      formattedData.treeData = { children: data };
      formattedData.multipleRoots = true;
    }
  }

  return formattedData;
};

export const getChartData = (filters, setLoading, setChartState, t) => {
  setLoading(true);
  axios
    .get("organisation/tree", {
      params: {
        "with": ["user.files", "user.userDetail.departments"],
        "filters[branch_id]": filters.branchFilter,
        "filters[department_id]": filters.departmentFilter,
        "filters[superior_id]": filters.superiorFilter
      }
    })
    .then((res) => {
      setChartState(res.data.data);
    })
    .catch((err) => {
      openGeneralErrorNotification({ error: err, defaultError: t("container.form_errors.internal_server_error") });
      setChartState([]);
      console.error(err);
    })
    .finally(() => setLoading(false));
};

export const getBranchesData = (setBranchesData) => {
  axios
    .get("organization-company-branches", {
      params: {
        "order_by_column[]": "name",
        "order_by_type[]": "asc",
        "with[]": "departments"
      }
    })
    .then((res) => setBranchesData(res.data.data))
    .catch((err) => {
      console.error(err);
      return [];
    });
};
